.save-btn {
  background-color: steelblue;
  color: #fff;
  border: 2px solid #00cc00;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}
.save-btn [disabled] {
  background-color: steelblue;
  color: #fff;
  border: 2px solid #00cc00;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}
.login-panel {
  margin-left: 10px;
  margin-right: 10px;
}
.title-header-animation {
  width: 35%;
}

.title-header-animation h5 {
  margin-top: 35px;
  text-align: center;
  font-weight: 300;
  font-size: 1.5rem;
}