@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow 0.75s linear infinite;
    animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.has-error .form-control {
    border-color: #ff4a55 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.alert-primary {
    color: #004085 !important;
    background-color: #cce5ff !important;
    border-color: #b8daff !important;
}

.btn-danger-up {
    color: #fff !important;
    background-color: #f50b04 !important;
    border-color: #d43f3a !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 2px !important;
}

.MuiFormControl-marginNormal {
    margin-top: 0px !important;
}

.login-panel {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    height: 140px;
    background-color: white;
}

.login-form {
    width: 80%;
    margin-top: 50px;
}

.font-size-14 {
    font-size: 14px !important;
    margin-bottom: 15px;
    padding-left: 15px;
}

.font-size-14 header {
    font-size: 14px !important;
    color: blue;
}

.selectContext {
    min-width: 400px;
    font-size: 12px;
}

/* DIALOG CONFIRM */
.btn-confirm-delete {
    font-size: small !important;
    color: #fff !important;
    background-color: #f50b04 !important;
    border-color: #d43f3a !important;
}

.btn-confirm-cancel {
    font-size: small !important;
    color: rgb(0, 0, 0) !important;
    background-color: rgba(0, 0, 0, 0.26) !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
}

.noDisplay {
    display: none;
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

.jawffx,
.rdt_TableBody {
    overflow-x: hidden !important;
}

.tab-error a {
    background-color: red !important;
    color: white !important;
}

.hideTitle h4 {
    display: none !important;
}

/* ERRORS LIST */
.list-error li {
    color: #f50b04;
}

.font-size-11 {
    font-size: 11px !important;
}

.fresh-datatables .wrapper {
    max-height: 32px !important;
    min-height: 10px !important;
}

.bootstrap-switch .bootstrap-switch-label {
    border-radius: 20px;
}

.bootstrap-switch {
    border-radius: 30px !important;
    border: 0 !important;
    margin-bottom: 5px !important;
}

.navbar-brand {
    width: 100% !important;
}

.navbar-title {
    width: 20% !important;
}

.navbar-brand button {
    float: right;
}
.navbar{
    padding-right: 1rem;
    padding-left: 0.5rem;
}

.badge {
    font-size: 70% !important;
}

.password-toogle-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 1000;
    cursor: pointer;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #3c3c3c;
    border: 1px solid rgba(0, 0, 0, 0.125);
    color: #23ccef;
}

/* menu */
.nav>li {
    position: relative;
    display: block;
    width: 100%;
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.sidebar .sidebar-wrapper .nav ul>li>a .sidebar-normal,
.sidebar .sidebar-wrapper .user .info ul>li>a .sidebar-normal {
    margin-left: 10px !important;
}

.fa_sidebar {
    position: relative;
    bottom: 5px;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-right: 1px solid #e3e3e3 !important;
    border-left: 1px solid #e3e3e3 !important;
}

.link_income {
    cursor: pointer;
    text-decoration: underline rgb(100, 99, 99);
    color: #fff !important;
}

.bg-header {
    background-color: #343e51 !important;
    background: linear-gradient(70deg, #252b35, #3f506d);
}

.btn-custom-sm {
    font-size: 12px !important;
    border-radius: 3px !important;
    padding: 8px 10px !important;
}

.col-nopadding-right {
    padding-right: 0;
}

.col-nopadding-left {
    padding-left: 2px;
}

.rdt_TableHeadRow {
    background-color: rgb(245 245 245) !important;
}

.swal-button--cancel {
    color: #fffafa !important;
    background-color: #ff4b56 !important;
}

.swal-button:not([disabled]):hover {
    background-color: #9fa1a7 !important;
    color: #000 !important;
}

.swal-button {
    background-color: #294b94;
    font-weight: 400 !important;
}

.small-font {
    font-size: 80% !important;
    font-weight: 400;
}
label  {
    font-size: 80% !important;
    font-weight: 400;
}

.sidebar .user,
.bootstrap-navbar .user {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 0px !important;
    margin-top: 20px;
}

.alert-danger.expiration-text {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 0px;
}

.header-logo {
    width: 120px;
}

.card .title {
    margin-bottom: 5px !important;
    color: #fbf4f4 !important;
    font-weight: 400 !important;
}

.card .header {
    background-color: #6b6666;
}

.card .content .title {
    color: #000 !important;
}

.form-control {
    border: 1px solid #b3b0b0 !important;
}

.headerTitle {
    padding: 5px 15px !important;
    margin: 2px 0px !important;
}

.navbar-title {
    width: 20% !important;
}

.content-container {
    min-height: calc(100vh - 91px);
}

body {
    overflow-x: hidden;
}

.content-max {
    max-height: calc(100vh - 101px);
}

.btn-group {
    padding-top: 10px;
}

.accordion-header {
    margin-bottom: 0;
    margin-top: 0;

}

.accordion-button {
    background-color: #6b6666;
    color: white;

}

.accordion-button:not(.collapsed) {
    background-color: #6b6666;
    color: white;
    border: 0;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23d8dde2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");

    transform: rotate(-180deg);
}


#react-select-3-listbox,#react-select-4-listbox ,#react-select-7-listbox,#react-select-2-listbox {
    z-index: 100000;
}
 .main-content {
    min-height: 100px !important;
}
.bg-warning{
    color:#000;
}
.form-vertical .row{
    padding-top: 5px;
}
.userTitle {
    font-size: .575em;
}
.font-30 {
    font-size: 30px;
}
.submenu {
    font-size: .575em;
}
.react-cookie-banner{
    z-index: 2 !important
}
.input-group-text{
    font-size:  0.875rem !important;
    font-weight: 700;
}