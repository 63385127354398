.list-group{
  max-height: 300px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
}
.custom-btn {
  background: #fff;
  color: red;
  border: 2px solid red;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}
.save-btn {
  background: #00cc00;
  color: #fff;
  border: 2px solid #00cc00;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}
.edit-btn {
  background: #447DF7;
  color: #fff;
  border: 2px solid #447DF7;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}
.delete-btn {
  background: red;
  color: #fff;
  border: 2px solid red;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}

/* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

/* mdl-demo is a custom className to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */

.mdl-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.mdl-demo .toggle {
  font: normal normal normal 18px/1 "Material Icons";
  color: #555;
  white-space: pre;
  margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
  cursor: pointer;
  content: "\E5CF";
  vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
  cursor: pointer;
  content: "\E5CE";
  vertical-align: middle;
}

/* checkbox styles */
.mdl-demo .checkbox-item {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl-demo .checkbox-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  transition: all 0.3s ease-in-out;
}

.mdl-demo .checkbox-item:checked:before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #ff3333;
}

// 
#treeList {
  max-height: auto;
}

.task-info {
  font-family: Segoe UI;
  min-height: 100px;
  display: flex;
  flex-wrap: nowrap;
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
}

.task-subject {
  line-height: 29px;
  font-size: 18px;
  font-weight: bold;
}
.progress {
  display: flex;
  flex-direction: column;
  white-space: pre;
  overflow: visible;
  min-width: 105px;
  background-color: transparent;
}
.task-progress {
  line-height: 42px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.task-status {
  font-size: 16px;
  text-align: center;
}
.cb-add-transaction {
  width: 600px;
}