// variables and mixins
@import "lbd/variables";
@import "lbd/mixins";

//plugins css
@import "lbd/plugins/animate";
@import "lbd/plugins/sweetalert2";

@import "lbd/plugins/jquery.jvectormap";
@import "lbd/plugins/bootstrap-table";
@import "lbd/plugins/datatables.net";

@import "lbd/plugins/chartist";
@import "lbd/plugins/bootstrap-select";
@import "lbd/plugins/fixed-plugin";

@import "lbd/plugins/nouislider";
@import "lbd/plugins/tags";
@import "lbd/plugins/_react-bootstrap-switch";
@import "lbd/plugins/_code-mirror";
@import "lbd/plugins/_react-simple-maps";
@import "lbd/plugins/_react-stepzilla";
@import "lbd/plugins/_react-big-calendar";
@import "lbd/plugins/_datetime-picker";


// Core CSS
@import "lbd/typography";
@import "lbd/sidebar-and-main-panel";
 @import "lbd/buttons";
 @import "lbd/misc";
 @import "lbd/inputs";
 @import "lbd/progress-bars";
 @import "lbd/sliders";
 @import "lbd/forms";
 @import "lbd/alerts";
 @import "lbd/tables";
 @import "lbd/tooltips-and-popovers";
 @import "lbd/checkbox-radio-switch";
 @import "lbd/navbars";
 @import "lbd/footers";

 
// Fancy Stuff
@import "lbd/social-buttons";
@import "lbd/dropdown";

@import "lbd/cards";

@import "lbd/collapse";
@import "lbd/carousel";

// SCSS for Pages
@import "lbd/pages.scss";

// Responsive adjustments
@import "lbd/responsive";
@import "lbd/tabs-navs-pagination";