.footer-dash{
    background-color: #3c3c3f !important;
    color: white;
    padding-top: 7px;
    overflow: overlay;
}
.footer--pin {
    position: relative;
    left: 0;
    bottom: 0;
  }


.footer-dash .powered{
    padding-bottom: 1px !important;
}
.footer-custom {
    overflow: overlay;
    color: white;
}
.footer-custom .powered{
    padding-right: 15px;
    padding-top: 15px;
    text-align:right;
    padding-bottom: 15px;
}